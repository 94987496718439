<script>
import '../css/Footer.css'

export default {
  name: 'Footer'
}

</script>

<template>
<div class="footer">
<div class="row">
<a href="https://discord.gg/MadFtX86sE"><i class="fa-brands fa-discord"></i></a>
<a href="https://twitter.com/Hedera_Monkeys"><i class="fa fa-twitter"></i></a>
<a href="#"><i class="fa fa-youtube"></i></a>
</div>

<div class="row">
Copyright © 2024 HEDERA MONKEYS - All rights reserved
</div>
</div>
</template>