const CONFIG = {
	currentNetwork: 'mainnet',
	mirrorNodeUrl:{
		"testnet":"https://testnet.mirrornode.hedera.com/",
		"mainnet":"https://mainnet-public.mirrornode.hedera.com/"
	},
	apiUrl:{
		"testnet":"http://localhost:1234",
		"mainnet":"https://api.hederamonkeys.com"
	},
	balanceUrl:`api/v1/tokens/0.0.2290122/balances?account.id=`,
	tokenID : '0.0.2290122',
	decimal:2,
	contractID:{
		"testnet":"0.0.498024",
		"mainnet":"0.0.3927013"
	},
	contractIDMint:{
		"testnet":"0.0.3927013",
		"mainnet":"0.0.4581799"
	},
	nftNames:{
		"0.0.1006183":"HEDERA MONKEY",
        "0.0.3914383":"EARLY MONKEY",
        "0.0.1071332":"VIPeel",
	},
	nftIDs:[
		"0.0.1006183",
        "0.0.3914383",
        "0.0.1071332",
	],
    tokenIDMint:{
		"testnet":"0.0.7743088",
		"mainnet":"0.0.4581624"
    },
	fees:1000
}; 

export default CONFIG