import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Vue3Lottie from 'vue3-lottie'

const pinia = createPinia()

import App from './App.vue'
import router from './router'

createApp(App).use(router).use(pinia).use(Vue3Lottie).mount('#app')
